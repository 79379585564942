<template>
  <nuxt-link
    class="grid cursor-pointer select-none gap-y-1"
    :to="`/event/${useShortId(event.id)}`"
    :title="event.name"
  >
    <div
      ref="element"
      :class="{
        'atropos': isLargeEnoughForAstropos,
        'rounded-md': true,
      }"
    >
      <div
        :class="{
          'atropos-scale': isLargeEnoughForAstropos,
          'rounded-md': true,
        }"
      >
        <div
          :class="{
            'atropos-rotate': isLargeEnoughForAstropos,
            'rounded-md': true,
          }"
        >
          <div
            :class="{
              'rounded-md': true,
              'border': true,
              'border-gray-300 dark:border-gray-700': true,
              'bg-white dark:bg-gray-900': true,
              'box-border': true,
            }"
          >
            <div
              :class="{
                'relative': true,
                'grid': true,
                'w-full': true,
                'h-0': true,
                '!pb-[50%]': true,
                'overflow-hidden': true,
                'box-border': true,
                'rounded-md': true,
                'shadow-sm': true,
                'transition-all': true,
                'items-center': true,
                'justify-items-center': true,
              }"
            >
              <nuxt-img
                v-if="typeof event.photo_url === 'string' && event.photo_url.length"
                :src="photoUrl ?? event.photo_url"
                :alt="`Image for ${event.name}`"
                :class="{
                  'absolute': true,
                  'w-[calc(100%_+_2px)]': true,
                  'h-[calc(100%_+_2px)]': true,
                  'object-contain': true,
                  'object-center': true,
                  'select-none': true,
                  'pointer-events-none': true,
                }"
                loading="lazy"
                draggable="false"
              />
              <nuxt-picture
                v-else
                format="webp"
                src="/logo-portal.png"
                class="pointer-events-none absolute select-none"
                sizes="150px sm:150px md:200px 2xl:250px"
                loading="lazy"
                :img-attrs="{
                  alt: 'The Show Portal',
                  draggable: false,
                }"
              />
              <u-badge
                v-if="status === 'live'"
                color="red"
                class="absolute right-2 top-2 text-white shadow-md dark:text-white"
              >
                Live
              </u-badge>
              <u-badge
                v-else-if="status === 'upcoming'"
                color="tspblue"
                class="absolute right-2 top-2 text-white shadow-md dark:text-white"
              >
                Upcoming
              </u-badge>
              <u-badge
                v-else-if="status === 'new'"
                color="green"
                class="absolute right-2 top-2 shadow-md"
              >
                New
              </u-badge>
              <u-badge
                v-else-if="status === 'draft'"
                color="yellow"
                class="absolute right-2 top-2 text-gray-900 shadow-md dark:text-gray-900"
              >
                Draft
              </u-badge>
              <u-badge
                v-else-if="status === 'ended'"
                color="gray"
                class="absolute right-2 top-2 shadow-md"
              >
                Ended
              </u-badge>
              <u-badge
                :class="{
                  'absolute': true,
                  'bottom-2': true,
                  'right-2': true,
                  'bg-tspbrown-50 dark:bg-tspbrown-300': true,
                  'text-tspbrown-900 dark:text-tspbrown-900': true,
                }"
              >
                {{ shortEventDates }}
              </u-badge>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden">
      <p class="truncate text-2xl">
        {{ event.metadata.is_draft ? '(Draft) ' : '' }}{{ event.name }}
      </p>
      <p class="truncate">
        <span class="text-sm font-medium">
          {{ event.facility }} &middot; {{ event.location }}
          <br>
          <span
            v-if="app.data.time.isAfter(event.registration_end_at)"
            class="text-gray-500 dark:text-gray-400"
          >
            Registration Closed
          </span>
          <template v-else-if="app.data.time.isBefore(event.registration_start_at)">
            Registration Opens:&nbsp;
            {{ useTime(event.registration_start_at).format('MMM D [at] h:mm A') }}
          </template>
          <template v-else>
            <span>Registration Closes:&nbsp;</span>
            <span class="text-tsppink-500 dark:font-bold dark:text-tsppink-400">
              {{ useTime(event.registration_end_at).format('MMM D [at] h:mm A') }}
            </span>
          </template>
        </span>
      </p>
    </div>
  </nuxt-link>
</template>

<script lang="ts" setup>
import atropos from 'atropos'

const props = defineProps<{
  event: PortalEvent
}>()
const app = useApp()
const element = ref<HTMLElement>()
const astro = ref<ReturnType<typeof atropos>>()
const { width: windowWidth } = useWindowSize()
const isLargeEnoughForAstropos = computed(() => windowWidth.value >= 768)
const formatTime = (time: string) => useTime(time).format('MMM D')
const photoUrl = ref<string | null>(null)
const extractDateParts = (date: string) => [
  date.slice(0, -2).trim(),
  date.slice(-2).trim(),
]
const shortEventDates = computed(() => {
  const startAt = formatTime(props.event.start_at)
  const endAt = formatTime(props.event.end_at)

  const [startMonth, startDay] = extractDateParts(startAt)
  const [endMonth, endDay] = extractDateParts(endAt)

  if (startAt === endAt) {
    return startAt
  }
  else if (startMonth === endMonth) {
    return `${startMonth} ${startDay} to ${endDay}`
  }

  return `${startAt} to ${endAt}`
})
const status = computed(() => {
  if (props.event.metadata.is_draft) {
    return 'draft'
  }
  else if (app.data.time.isBetween(props.event.start_at, props.event.end_at)) {
    return 'live'
  }
  else if (app.data.time.diff(props.event.created_at, 'd') < 2) {
    return 'new'
  }
  else if (app.data.time.isAfter(props.event.end_at)) {
    return 'ended'
  }
  else if (useTime(props.event.start_at).diff(app.data.time, 'd') < 8) {
    return 'upcoming'
  }
  else {
    return undefined
  }
})

onMounted(() => {
  if (element.value && windowWidth.value >= 768) {
    astro.value = atropos({
      el: element.value,
      shadowOffset: 12,
      shadowScale: 0.6,
      rotateYMax: 4,
      rotateXMax: 4,
      duration: 150,
      activeOffset: 7,
      highlight: false,
    })
  }

  photoUrl.value = props.event.photo_url
})

onUnmounted(() => {
  if (astro.value) {
    astro.value.destroy()
  }
})

watch(
  windowWidth,
  (updatedValue) => {
    if (astro.value) {
      if (updatedValue <= 768) {
        astro.value.isActive = false
      }
      else {
        astro.value.isActive = true
      }
    }
  },
  { immediate: true },
)
</script>
